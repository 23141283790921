const FINGER_TOKEN_PREFIX = process.env.NEXT_PUBLIC_FINGER_TOKEN_PREFIX;
const MC_AUTO_AUTH_TOKEN_PREFIX = process.env.NEXT_PUBLIC_MC_AUTO_AUTH_TOKEN_PREFIX;
const FINGER_UNIQUE_VALUE = process.env.NEXT_PUBLIC_FINGER_UNIQUE_VALUE;

export const AFTER_LOGIN_REDIRECT_TO = 'after_login_redirect_to';
export const AFTER_LOGIN_OPEN_DIALOG = 'after_login_open_dialog';
export const AFTER_ABOUT_REDIRECT_TO = 'after_about_redirect_to';
export const STATE_TO_CHANGE = 'state_to_change';

export const TYPE_NOTES_PATH = 'type_notes';
export const TYPE_NOTES_NAME = 'MEMOTAGPRO';
export const MC_NOTES_PATH = 'm_c_notes';
// export const UPLOAD_IMAGES = 'uploads/upload_file';
// export const UPLOAD_IMAGES = 'medias';
export const UPLOAD_MEDIAS = 'medias';
export const TYPE_NOTES = 'type_notes';
export const AVATAR_TRACEABILITY_NOTES_TYPE_MCNOTEHISTORY = 'MCNoteHistory';
export const AVATAR_TRACEABILITY_NOTES = 'avatar_traceability_notes';
export const METADATA_AVATAR_TYPES = 'metadata_avatar_types';
export const METADATA_AVATAR_TYPE_PROPERTIES = 'metadata_avatar_type_properties';
export const DEFAULT_START_CONTROL_PROCESSES = 'default_start_control_processes';
export const MCS_CROSS_STOCK = 'm_cs/cross-stock';
export const TEMPORARY_YOURLS = 'temporary_yourls';
export const AVATAR_TRACEABILITY = 'traceability/current_referencing';
export const AVATARS = 'avatars';
export const LANGUAGES = 'languages';
export const AUTO_APP_INSTANCES = 'create_auto_auth_app_instances';
export const AVATAR_TEMPLATES = 'avatar_templates';
export const IMAGES = 'image';
export const FILES = 'files';
export const MEDIA = 'media';
export const TRACEABILITY_TRACKING = 'traceability/tracking';
export const TRACEABILITY_TRACKING_V2 = 'traceability/tracking/v2/change-node-0';

export const SIMPLE_ZONES = 'simple_zones';
export const ZONE_CONFIGS = 'zone_configs';
export const USER_CONNECTED = 'users';
export const GROUP_AVATARS = 'group_avatars';
export const ADD_GROUP = 'add_group';
export const REMOVE_GROUP = 'remove_group';
export const METADATA_LABELS = 'metadata_labels';
export const COMPANIES = 'companies';
export const TOKEN_WITH_ROLES = 'token-connected';
export const AVATAR_PROPERTIES = 'avatar_properties';
export const SEND_EMAILS = 'send_emails';
export const NOTE_HISTORY = 'm_c_note_histories';
export const TRACEABILITY_HISTORY = 'traceability_histories';
export const GROUP_METADATA_AVATAR_TYPES = 'group_metadata_avatar_types';

export const DATA_SOURCE_FINGER = 'MemoTAGPRO';
export const DATA_SOURCE_BACKOFFICE = 'backoffice';

export const InitialValuesRoute = '/initial-values';

export const UNIQUE_IDENTIFIER_BY_FINGER = FINGER_UNIQUE_VALUE;
export const METADATA_AVATAR_TYPE_EQUALPROCESSDEFNITION_MAPPER_MEMOTAG_DATA = 'MEMOTAGPRO';

export const MEDIA_FILE_TYPE = 'FILE';
export const MEDIA_IMAGE_TYPE = 'IMAGE';
export const MEDIA_LINK_TYPE = 'LINK';
export const MEDIA_AUDIO_TYPE = 'AUDIO';
export const RESOURCE_SUPPORTED_MEDIAS = 'avatar';
export const UPLOAD_IMAGES = 'medias';

export const NOTE_MEDIA_FILE = 'File';
export const NOTE_MEDIA_AUDIO = 'Audio';
export const NOTE_MEDIA_IMAGE = 'Image';

export const AUTHENTICATION_TYPE = 'Bearer';

export const MAIN_PAGE = '/main';
export const ROOT_PAGE = '/';
export const CREATE_AVATAR_PAGE = '/create-avatar';
export const LOGIN_PAGE = '/login';
export const PROCESS_PAGE = '/process';
export const FINISH_PAGE = '/finish';
export const ABOUT_PAGE = '/about';

export const AVATAR_CREATION_TYOURLS_STORAGE = '/avatar-creation-tyourls-storage';

//Modes

export const TOKEN_PARTS_PREFIX = `${FINGER_TOKEN_PREFIX}-tokenPart`;
export const TOKEN_PARTS_SIZE = `${FINGER_TOKEN_PREFIX}-tokenPartsSize`;

export const TOKEN_PARTS_MC_AUTO_AUTH_PREFIX = `${MC_AUTO_AUTH_TOKEN_PREFIX}-tokenPart`;
export const TOKEN_PARTS_MC_AUTO_AUTH_SIZE = `${MC_AUTO_AUTH_TOKEN_PREFIX}-tokenPartsSize`;
export const PAIRING_MODE = 'PAIRING_MODE';
export const SC360_PLANIFICATION_SERVICE = 'SC360_PLANIFICATION_SERVICE';
export const SC360_PAIRING_FROM_LINK = 'SC360_PAIRING_FROM_LINK';

export const INTEGRATION_ID = 'INTEGRATION_ID';
export const METADATAAVATARTYPEURI = 'METADATAAVATARTYPEURI';
export const PRODUCT_INFORMATIONS = 'PRODUCT_INFORMATIONS';
export const METADATALABEL_BUSINESSURI = 'METADATALABEL_BUSINESSURI';
export const METADATALABEL_BUSINESS = 'METADATALABEL_BUSINESS';
export const METADATALABEL_CONSTRUCTION_SITE = 'METADATALABEL_CONSTRUCTION_SITE';
export const METADATALABEL_CONSTRUCTION_SITEURI = 'METADATALABEL_CONSTRUCTION_SITEURI';
export const METADATALABEL_FACTORYURI = 'METADATALABEL_FACTORYURI';
export const METADATALABEL_FACTORY = 'METADATALABEL_FACTORY';
export const AVATAR_CLIENT_ID = 'AVATAR_CLIENT_ID';
export const AVATAR_CLIENT_NAME = 'AVATAR_CLIENT_NAME';
export const VALIDATION_TIME = 'VALIDATION_TIME';
export const LINKED_SOURCE_INFORMATION = 'LINKED_SOURCE_INFORMATION';
export const RESOURCE_CLIENT_ID = 'RESOURCE_CLIENT_ID';
export const CLASS_MAPPER = 'CLASS_MAPPER';
export const RAW_PROPERTIES = 'RAW_PROPERTIES';

export const CORE_INTEGRATION_CLASS_MAPPER_SUPPLEMENTS =
    'Platform360\\Core\\Domain\\Avatar\\AvatarClassMapperSupplements\\CoreIntegrationClassMapperSupplements';
export const EXTERNAL_SYSTEM_INTEGRATION_CLASS_MAPPER_SUPPLEMENTS =
    'Platform360\\Core\\Domain\\Avatar\\AvatarClassMapperSupplements\\ExternalSystemIntegration';

export const TOKEN_MAX_PARTS_SIZE = 2000;
// DEPRECATED
export const LOGIN_ANONYMOUS = 'optimized_token_semi_anonymous_easy_login_check';

export const LOGIN_OPTIMIZED_TOKEN = 'optimized_token_login_check';

export const TRACEABILITY_LINEAL = 'lineal';
export const TRACEABILITY_DECLARATIVE = 'declarative';
export const TRACEABILITY_DECLARATIVE_FORWARD = 'declarative_forward';
export const FINISH_PRODUCE_TOKEN = 'FINISH_PRODUCE_TOKEN';
export const ROLE_FILE_MANAGER_V1 = 'ROLE_FILE_MANAGER_V1';
export const ROLE_CORE_SC_PUBLISH_TOKEN = 'ROLE_CORE_SC_PUBLISH_TOKEN';

/*File Manager Cloud Roles**/
export const ROLE_FILE_MANAGER_CLOUD_READ = 'ROLE_FM_CLOUD_READ';
export const ROLE_FILE_MANAGER_CLOUD_READ_DOWNLOAD = 'ROLE_FM_CLOUD_READ_DOWNLOAD';
export const ROLE_FILE_MANAGER_CLOUD_WRITE = 'ROLE_FM_CLOUD_WRITE';

export const ROLE_CREATE_NFC_BADGE = 'ROLE_CREATE_NFC_BADGE';
export const ROLE_UPDATE_AVATAR_PROPERTY = 'ROLE_UPDATE_AVATAR_PROPERTY';
export const ROLE_CREATE_AVATAR_PROPERTY = 'ROLE_CREATE_AVATAR_PROPERTY';
export const ROLE_READ_AVATAR_PROPERTY = 'ROLE_READ_AVATAR_PROPERTY';

export const PROPERTY = 'Propriété';
export const VALUE = 'Valeur';

export const AUTO_ACTIONS = 'autoactions';
export const AUTO_ACTIONS_VERSION_1 = 'change-next-node:v1';
export const AUTO_ACTIONS_RESTART_VERSION_1 = 'change-next-node-restart:v1';
export const AUTO_ACTIONS_CHANGE_TIME = 'AUTO_ACTIONS_CHANGE_TIME';

export const NOTE_TYPE = 'note';
export const TRACEABILITY_TYPE = 'traceability';

export const MAINTENANCE_TAG = 'maintenance';
export const EMPTYING_TAG = 'emptying';
export const SPANC_TAG = 'spanc';
export const COMMENT_TAG = 'comment';
export const AIR_TABLE_TOKEN_IDENTIFIER = 'airtable-token';

export const INITIAL_MESSAGE_KEY = 'INITIAL_MESSAGE';

export const NODE_TYPE_END_FAILED = 'NODE_TYPE_END_FAILED';
export const NODE_TYPE_END = 'NODE_TYPE_END';

export const RIKUTEC_FACTURATION_ID = 'rikutec-001';
export const RIKUTEC_TEST_FACTURATION_ID = 'rikutec-test-001';


export const ID_FOR_NODE_HUMAN_READABLE = 'idForNodeHumanReadable';

export const ANNEXED_D = 'annexed_d';
export const ANNEXED_B = 'annexed_b';
export const VERBAL_PROCESS = 'process_verbal';
export const WARRANTY_CERTIFICATE = 'warranty_certificate';
export const APPROVAL = 'approval';
export const MAINTENANCE_INSTRUCTIONS_KEY = 'maintenanceInstructions';
export const CALL_MAINTENANCE_KEY = 'callMaintenance';
export const USER_GUIDE = 'user_guide';
export const USER_BOOKLET = 'user_booklet';
export const TECHNICAL_SHEET = 'technical_sheet';
export const INSTALLING_GUIDE = 'installing_guide';
export const REFLEX_EMTYING = 'reflex_emptying';
export const REFLEX_MAINTENANCE = 'reflex_maintenance';
export const PROCESS_VERBAL = 'process_verbal';

export const ENABLE_APPROVAL_PROPERTY_NAME = 'enableApproval';

export const FILE_SYSTEM_MAPPER_ENTRYPOINT = process.env.NEXT_PUBLIC_FILE_SYSTEM_MAPPER_ENTRYPOINT;

export const POSER_STATE_KEY = 'Poser';
export const PRETE_A_LIVRER_KEY = 'prête à livrer';

export const NEXT_LOCALE_KEY = 'NEXT_LOCALE';

export const LOCAL_STORAGE_DEFAULT_CAMERA_DEVICE_ID = 'default_camera_device';

export const GLOBAL_NOTE_TYPE = 'GlobalNote';
export const RIKUTEC_GLOBAL_NOTE_TYPE = 'RikutecGlobalNote';
export const EMPTYING_NOTE_TYPE = 'EmptyingNote';

export const METADATA_AVATAR_TYPE_PROPERTY_CHANGE_NODE_CHOICES_NAME = 'Vous êtes';

export const CONDITION_FORM_NAME = '_form7';
